<template>
  <div :class="classes.container">
    <label :for="id" :class="classes.label">Search</label>
    <input
      :id="id"
      v-model="innerValue"
      type="search"
      :class="classes.input"
      placeholder="Search LOINC"
      v-bind="$attrs"
    />

    <div :class="classes.end">
      <VtButton
        type="submit"
        display="icon-only"
        intent="primary"
        icon="magnifying-glass"
        text="Search"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { v4 as uuid } from 'uuid'

import { VtButton } from '@visiontree/vue-ui'

import { getSearchInputClasses } from './utils/getSearchInputClasses'

interface SearchInputProps {
  id?: string
  modelValue?: string
}

const props = withDefaults(defineProps<SearchInputProps>(), {
  id: uuid(),
  modelValue: '',
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const innerValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const classes = computed(() => {
  return getSearchInputClasses()
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
